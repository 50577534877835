import React from 'react';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent'; // https://www.npmjs.com/package/react-cookie-consent

import Header from '../header/header';
import { lng } from '../../config/language';

const Layout = ({ children }) => {
	return (
		<>
			<CookieConsent
				location="none"
				cookieName="cc-accept"
				buttonText={lng('cookiesConsentOkText')}
				expires={150}
				disableStyles
				acceptOnScroll
			>
				{lng('cookiesConsentText')}
			</CookieConsent>
			<Header />
			{children}
		</>
	);
};

Layout.defaultProps = {
	children: null
};

Layout.propTypes = {
	children: PropTypes.node
};

export default Layout;
