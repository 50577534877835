import React from 'react';
import PropTypes from 'prop-types';

import styles from './calculator.module.scss';
import { lng } from '../../config/language';
import { useStore as calculatorStore } from '../../contexts/calculator';

const CalculatorHeader = React.memo(({ title, credit, backBtn, redoBtn }) => {
	const { dispatch } = calculatorStore();

	return (
		<header className={styles.header}>
			<h2 dangerouslySetInnerHTML={{ __html: title }} />
			{credit !== null && <div className={styles.amount}><span>€</span>{credit}<span>{lng('labelPerMonth')}</span></div>}
			{backBtn && <button type="button" aria-label="na prejšnji korak" className={styles.back} onClick={() => dispatch({ type: 'STEP_PREV' })}><i className="icon arrow-left-gray" /></button>}
			{redoBtn && <button type="button" aria-label="reset in nazaj na začetek" className={styles.redo} onClick={() => dispatch({ type: 'RESET_CALCULATOR' })}><i className="icon redo" /></button>}
		</header>
	);
});

CalculatorHeader.defaultProps = {
	title: '',
	credit: null,
	backBtn: false,
	redoBtn: false
};

CalculatorHeader.propTypes = {
	title: PropTypes.string,
	credit: PropTypes.number,
	backBtn: PropTypes.bool,
	redoBtn: PropTypes.bool
};

export default CalculatorHeader;
