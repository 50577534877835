import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ children, name, multiline, value, onClick, checked }) => {
	const idx = Math.random();
	const [isChecked, setChecked] = useState(checked);

	const onChange = (e) => {
		setChecked(!isChecked);
		onClick(e);
	};

	return (
		<label className={`checkbox ${multiline ? ' multiline' : ''}`} htmlFor={idx}>
			{children}
			<input type="checkbox" name={name} value={value} id={idx} onChange={onChange} checked={isChecked} />
			<span className="checkmark" />
		</label>
	);
};

Checkbox.defaultProps = {
	multiline: false,
	value: 1,
	checked: false
};

Checkbox.propTypes = {
	children: PropTypes.node.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	multiline: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	checked: PropTypes.bool
};

export default Checkbox;
