export const language = {
	// Hero
	heroTitle: 'Enostavno do najboljše ponudbe za kredit',
	heroDescription:
	'Potrebujete kredit? Oddajte povpraševanje več bankam naenkrat.',

	// Calculator
	calcHomeTitle: 'Informativni mesečni obrok',
	calcDataTitle: 'Vaši podatki',
	calcContactTitle: 'Vaš kontakt',
	calcCreditBottomTitle: 'Mesečni obrok',
	calcBankListText: 'Izberite od katerih bank želite ponudbo',
	calcBankCtaText: 'Pridobite ponudbo več bank naenkrat',

	// Common
	labelPerMonth: '/mesec',
	labelNeed: 'Potrebujem',
	labelRepaymentPeriod: 'Doba odplačevanja',
	labelMonthlyIncome: 'Mesečni prihodki kreditojemalca',
	labelMonthlyIncomeDesc: '(neto plača, prevoz na delo, malica)',
	labelChildCnt: 'Št. otrok v družini do 18. leta (ali 26. če se redno šolajo)',
	labelDependents: 'Vzdrževane odrasle osebe',
	labelFamily: 'Družina',
	labelFamilyType1: 'Dvostarševska', // če spremeniš, spremeni tudi v calcualtor-data valToId!!!!
	labelFamilyType2: 'Enostarševska', // če spremeniš, spremeni tudi v calcualtor-data valToId!!!!
	labelFamilyType3: 'Brez vzdrževanih članov', // če spremeniš, spremeni tudi v calcualtor-data valToId!!!! + context/calculator.js - family defaultValues
	labelCreditTime: 'Kako hitro boste potrebovali kredit',
	labelTimeType1: 'Takoj',
	labelTimeType2: '1 mesec',
	labelTimeType3: '6 mesecev',
	labelMore: 'Več',
	labelGivenname: 'Ime',
	labelSurname: 'Priimek',
	labelName: 'Ime in priimek',
	labelEmail: 'Email',
	labelPhone: 'Tel. št.',
	labelInit: 'Nalagam ...',

	validationRequired: 'Obvezno polje',
	validationGivenname: 'Neveljavno ime',
	validationSurname: 'Neveljaven priimek',
	validationEmail: 'Neveljaven e-mail',

	notificationErrorTitle: 'Ups!',
	notificationErrorText1:
	'<p>Glede na višino vaših prihodkov in št. vzdrževanih družinskih članov, vam banke zaradi zakonskih omejitev ne morejo odobriti kredita v želeni višini.</p>',
	notificationErrorText2:
	'<p>Glede na višino vaših prihodkov in št. vzdrževanih družinskih članov, vam banke zaradi zakonskih omejitev ne morejo odobriti kredita v želeni višini.<p><p>Prilagodite vpisane podatke na prvem in drugem koraku.</p>',
	notificationErrorText3:
	'<p>Glede na višino vaših prihodkov vam banke zaradi zakonskih omejitev ne morejo odobriti kredita v želeni višini.</p>',
	notificationErrorMore1:
	'Prilagodite vpisane podatke na prvem in drugem koraku.',
	notificationErrorMore2:
	'<strong>Pojasnilo:</strong> pri izračunu za dvostarševsko družino se izračunava obrok kredita posameznega starša, ne obeh skupaj.',
	notificationErrorMore3:
	'Prilagodite vpisane podatke na prvem in drugem koraku.',

	notificationSuccessTitle: 'Hvala!',
	notificationSuccessText:
	'Povpraševanje smo posredovali izbranim bankam, ki vas bodo kmalu kontaktirale s svojo ponudbo.',

	formContactText:
	'Vnesite podatke, da vas bodo banke lahko kontaktirale s ponudbo.',
	formContactGdpr: 'Potrjujem',

	formContactGdprText:
	'<p>S potrditvijo tega obrazca potrjujete in soglašate:</p><ul><li>da družba 100kvadratov d.o.o., Partizanska cesta 5, 2000 Maribor, matična številka: 6912281000 (v nadaljevanju: 100kvadratov) kot upravljalec upravlja, obdeluje ter posreduje vaše osebne podatke bankam in njihovim poslovnim partnerjem ter svojim pogodbenim partnerjem za namen kontaktiranja s ponudbo za bančno storitev kreditiranja,</li><li>da vas družba 100kvadratov d.o.o. in banke kot uporabniki vaših osebnih podatkov kontaktirajo preko emaila, telefona ali sms-a,</li><li>s <a href="https://www.izracun-kredita.si/files/politika-varstva-osebnih-podatkov.pdf" target="_blank" rel="noreferrer noopener">Politiko varstva osebnih podatkov</a> in <a href="https://www.izracun-kredita.si/files/piskotki.pdf" target="_blank" rel="noreferrer noopener">Politiko piškotkov družbe 100kvadratov</a> in</li><li>da 100kvadratov posreduje vaše osebne podatke tretjim pravnim in fizičnim osebam kot uporabnikom in obdelovalcem v smislu ZVOP-1 in GDPR Uredbi, za namene in v obsegu, kot so določeni s <a href="https://www.izracun-kredita.si/files/politika-varstva-osebnih-podatkov.pdf" target="_blank" rel="noreferrer noopener">Politiko varstva osebnih podatkov</a>, še posebej za namen priprave ponudbe storitve kreditiranja s strani posamezne banke.</li></ul>',

	formContactGdpr1: 'da družba 100kvadratov d.o.o., Partizanska cesta 5, 2000 Maribor, matična številka: 6912281000 (v nadaljevanju: 100kvadratov), kot upravljavec osebnih podatkov v smislu ZVOP-2 in GDRP Uredbe, upravlja in obdeluje vaše osebne podatke, pridobljene na spletni strani www.izracun-kredita.si, v skladu s <a href="https://www.izracun-kredita.si/files/politika-varstva-osebnih-podatkov.pdf" target="_blank" rel="noreferrer noopener">Politiko varstva zasebnosti</a> in <a href="https://www.izracun-kredita.si/files/piskotki.pdf" target="_blank" rel="noreferrer noopener">Politiko piškotkov družbe</a> 100 kvadratov;',
	formContactGdpr2: 'posreduje vaše osebne podatke, pridobljene na spletni strani www. izracun-kredita.si, izbranim bankam za namen priprave ponudbe storitve kreditiranja s strani posamezne banke in kontaktiranja s strani banke preko emaila, telefona ali sms-a.',

	btnGetBankOffer: 'Pridobite ponudbo', // ... bank
	btnNext: 'Naprej',
	btnStart: 'Na začetek',
	btnRepeat: 'Znova',

	tagPayedPost: 'Oglasna objava',

	labelLink1: 'Splošni pogoji',
	labelLink2: 'Politika varstva osebnih podatkov',
	labelLink3: 'Piškotki',

	// Modal
	modalBSModifyTitle: 'Povpraševanje v pošiljanju',
	modalBSModifySubtitle: 'Želite morda izboljšati vaše možnosti odobritve kredita?',
	modalBSModifyText: 'Glede na vaše podatke smo prilagodili znesek in dobo odplačevanja na stanje, ki še ustreza pogojem Banke Slovenija za odobritev kredita.',
	modalBSModifyBoxAmountTitle: 'Predlagan znesek',
	modalBSModifyBoxAmountDisclaimer: 'Prvotni znesek',
	modalBSModifyBoxPeriodTitle: 'Predlagana doba odpl.',
	modalBSModifyBoxPeriodDisclaimer: 'Prvotna doba odpl',
	modalBSModifyBtnOk: 'Naprej',
	modalBSModifyBtnCancel: 'Uporabi prvotne',

	cookiesConsentText:
	'Na spletni strani uporabljamo piškotke, s pomočjo katerih izboljšujemo Vašo uporabniško izkušnjo in zagotavljamo kakovostne vsebine. Z nadaljnjo uporabo se strinjate z uporabo piškotkov.',
	cookiesConsentOkText: 'Vredu',
	footerDisclaimer: '* Informativni mesečni obrok je izključno informativne narave in ne predstavlja ponudbe za sklenitev kredita ali kakršnekoli poslovne zaveze družbe 100kvadratov ali bank. Izračun je namenjen informaciji uporabnika spletne strani Izračun-kredita.si o približni višini mesečnega obroka za določen znesek stanovanjskega kredita ob določeni dobi odplačevanja ter je pri izračunu mesečnega obroka uporabljena zgolj informativna obrestna mera 3,8%. Za pravilen izračun in ponudbo je potrebno pridobiti ponudbe bank, na podlagi katerih je mogoče presoditi ustreznost kredita glede na svoje potrebe in finančni položaj.'
};

export const lng = (key) => {
	return language[key] || null;
};
