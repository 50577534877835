import React, { useState } from 'react';
import Slider from 'react-input-slider'; // https://www.npmjs.com/package/react-input-slider
import PropTypes from 'prop-types';

import { formatPrice, replaceAll } from '../../helpers/number';

const InputSlider = ({ name, children, inputSize, inputSuffix, min, max, step, value, onChange }) => {
	const [state, setState] = useState({ x: value });

	const sliderStyle = {
		track: {
			backgroundColor: '#EFF4FB',
			height: 5,
			width: '100%'
		},
		active: {
			backgroundColor: '#EA4C89'
		},
		thumb: {
			width: 27,
			height: 27,
			backgroundColor: '#EA4C89',
			border: '6px solid #fff',
			boxShadow: '0px 0px 20px #525F7F29'
		},
		disabled: {
			opacity: 0.5
		}
	};

	const setValue = (e) => {
		if (e.cancelable) {
			e.preventDefault();
		}

		const event = {
			target: {
				name,
				value: e.target ? replaceAll('.', '', e.target.value) : e.x
			}
		};

		setState({ x: event.target.value });
		onChange(event);
	};

	return (
		<div className="sliderInput">
			<header>
				{children && <span className="label">{children}</span>}
				<label htmlFor={name} className="input-label">
					<input type="text" name={name} value={formatPrice(state.x)} onChange={setValue} className={inputSize} />
					{inputSuffix && <span>{inputSuffix}</span>}
				</label>
			</header>
			<div className="slider">
				<Slider axis="x" xmax={max} xstep={step} x={state.x} styles={sliderStyle} onChange={setValue} />
			</div>
		</div>
	);
};

InputSlider.defaultProps = {
	children: null,
	inputSize: 'md',
	inputSuffix: '',
	min: 0,
	max: 100,
	step: null,
	value: 10
};

InputSlider.propTypes = {
	name: PropTypes.string.isRequired,
	children: PropTypes.node,
	inputSize: PropTypes.string,
	inputSuffix: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	value: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
	onChange: PropTypes.func.isRequired
};

export default InputSlider;
