import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import styles from './header.module.scss';
import logo from '../../assets/img/izracun-kredita-logo.svg';
import { testMobile } from '../../helpers/common';

const Header = () => {
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		// REŠI PREKO CONTEXT!!
		if (window && testMobile()) {
			document.addEventListener('scroll', () => {
				const scrollCheck = window.scrollY > 350;
				if (scrollCheck !== scroll) setScroll(scrollCheck);
			});
		}
	});

	return (
		<header className={`${styles.base} ${scroll ? 'onScrollHide' : 'onScrollShow'}`}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-6"><Link to="/"><img src={logo} alt="logo" /></Link></div>
					<div className={`col-6 ${styles.menu}`}><i className="icon bars" /></div>
				</div>
			</div>
		</header>
	);
};

export default Header;
